export default {
  fonts: {
    default: "Lato, sans-serif",
    secondary: "Nunito, sans-serif",
  },
  weights: {
    regular: 400,
    bold: 700,
  },
}
